var gold_data = require("../data/gold_data.json");
var silver_data = require("../data/silver_data.json");
var crystal_data = require("../data/crystal_data.json");

export const CRYSTAL_LIST = crystal_data.map((card) => {
  card["rarity"] = "crystal";
  return card;
});
export const GOLD_CARD_LIST = gold_data
  .map((card: any) => JSON.parse(card))
  .filter((card: any) => {
    return card != null && card.properties && card.properties.baseCardId;
  })
  .map((card: any) => {
    let newCard = {};
    newCard["baseID"] = card.properties.baseCardId.toString();
    newCard["ID"] = (card.properties.baseCardId + 131072).toString();
    newCard["name"] = card.name.replace(" (Gold)", "");
    newCard["Image_URL"] = card.image;
    newCard["rarity"] = "gold";
    return newCard;
  });
export const SILVER_CARD_LIST = silver_data
  .map((card: any) => JSON.parse(card))
  .filter((card: any) => {
    return card != null && card.properties && card.properties.baseCardId;
  })
  .map((card: any) => {
    let newCard = {};
    newCard["baseID"] = card.properties.baseCardId.toString();
    newCard["ID"] = (card.properties.baseCardId + 65536).toString();
    newCard["name"] = card.name.replace(" (Silver)", "");
    newCard["Image_URL"] = card.image;
    newCard["rarity"] = "silver";
    return newCard;
  });
export const LIQUIDITY_CARD_LIST = CRYSTAL_LIST;
// export const LIQUIDITY_CARD_LIST = gold_data
//   .map((card: any) => JSON.parse(card))
//   .filter((card: any) => {
//     return card != null && card.properties && card.properties.baseCardId;
//   })
//   .filter((card: any) => {
//     if (
//       card.name === "Doom Shroom (Gold)" ||
//       card.properties.baseCardId === 4015 || //"Starfield"
//       card.properties.baseCardId === 10 // Call to Action
//     ) {
//       return true;
//     }
//     return false;
//   })
//   .map((card: any) => {
//     let newCard = {};
//     newCard["baseID"] = card.properties.baseCardId.toString();
//     newCard["ID"] = (card.properties.baseCardId + 131072).toString();
//     newCard["name"] = card.name.replace(" (Gold)", "");
//     newCard["Image_URL"] = card.image;
//     newCard["supply"] = 800;
//     return newCard;
//   });

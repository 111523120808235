import { chakra, extendTheme } from "@chakra-ui/react";

export default extendTheme({
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
  styles: {
    global: () => ({
      option: {
        background: "blackAlpha.900",
      },
    }),
  },
  breakpoints: {
    sm: "320px",
    md: "768px",
    lg: "1080px",
    xl: "1400px",
    xxl: "1720px",
  },
});

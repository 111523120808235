import { Box, Text, Button, useDisclosure } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useGlobalState } from "../../hooks/GlobalState";
import ConfirmTradeModal from "./ConfirmTradeModal";
import { EXCHANGE_ADDRESS, USDC_ABI, USDC_CONTRACT } from "../constant";
import { useTranslation } from "react-i18next";
import styles from "../../css/web3.module.css";

export default function ApproveUSDCButton() {
  const { t, i18n } = useTranslation("translation");
  const [globalState, dispatch] = useGlobalState();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const approve_usdc = async () => {
    if (!globalState.account) {
      return;
    }

    const approval = globalState.usdcContract.approve(
      EXCHANGE_ADDRESS,
      ethers.constants.MaxUint256
    );
    // const approval = usdc_contract.methods.approve(EXCHANGE_ADDRESS, 1000000000);
    if (await approval) {
      onConfirmOpen();
    }
    try {
      await approval.wait();
      dispatch({ needApproval: false });
    } catch {}
  };
  return (
    <Box marginTop={"10px"} marginBottom={"10px"} textAlign="center">
      <Text color={"white"} marginBottom={"4px"}>
        {t("translation:market.card.approvalIntro")}
      </Text>
      <button
        className={[
          styles.button,
          styles.buttonGradientBorder,
          styles.buttonGlow,
          styles.smallButton,
        ].join(" ")}
        onClick={approve_usdc}
      >
        {t("translation:market.card.approveButton")}
      </button>
      <ConfirmTradeModal isOpen={isConfirmOpen} onClose={onConfirmClose} />
    </Box>
  );
}

import { BigNumber, ethers } from "ethers";

// Buy and sell data for ERC-20 exchange
export const getSellTokenData20 = (
  recipient: string,
  cost: BigNumber,
  deadline: number,
  extraFeeRecipients?: string[],
  extraFeeAmounts?: BigNumber[]
) => {
  const sellTokenObj = {
    recipient: recipient,
    minCurrency: cost,
    extraFeeRecipients: extraFeeRecipients ? extraFeeRecipients : [],
    extraFeeAmounts: extraFeeAmounts ? extraFeeAmounts : [],
    deadline: deadline,
  } as SellTokensObj20;

  return ethers.utils.defaultAbiCoder.encode(
    ["bytes4", SellTokens20Type],
    [methodsSignature20.SELLTOKENS, sellTokenObj]
  );
};

export type AddLiquidityObj = {
  maxCurrency: number[] | string[] | BigNumber[];
  deadline: number | string | BigNumber;
};

export type RemoveLiquidityObj = {
  minCurrency: number[] | string[] | BigNumber[];
  minTokens: number[] | string[] | BigNumber[];
  deadline: number | string | BigNumber;
};

export const AddLiquidityType = `tuple(
    uint256[] maxCurrency,
    uint256 deadline
  )`;

export const RemoveLiquidityType = `tuple(
    uint256[] minCurrency,
    uint256[] minTokens,
    uint256 deadline
  )`;

export const BuyTokensType = `tuple(
    address recipient,
    uint256[] tokensBoughtIDs,
    uint256[] tokensBoughtAmounts,
    uint256 deadline
  )`;

export const SellTokensType = `tuple(
    address recipient,
    uint256 minBaseTokens,
    uint256 deadline
  )`;

export const BuyTokens20Type = `tuple(
    address recipient,
    uint256 maxCurrency,
    address[] extraFeeRecipients,
    uint256[] extraFeeAmounts,
    uint256 deadline
  )`;

export const SellTokens20Type = `tuple(
    address recipient,
    uint256 minCurrency,
    address[] extraFeeRecipients,
    uint256[] extraFeeAmounts,
    uint256 deadline
  )`;

export const methodsSignature = {
  BUYTOKENS: "0xb2d81047",
  SELLTOKENS: "0xdb08ec97",
  ADDLIQUIDITY: "0x82da2b73",
  REMOVELIQUIDITY: "0x5c0bf259",
};

export const methodsSignature20 = {
  BUYTOKENS: "0xb2d81047",
  SELLTOKENS: "0xade79c7a",
  ADDLIQUIDITY: "0x82da2b73",
  REMOVELIQUIDITY: "0x5c0bf259",
};

export type BuyTokensObj = {
  recipient: string;
  tokensBoughtIDs: number[] | string[] | BigNumber[];
  tokensBoughtAmounts: number[] | string[] | BigNumber[];
  deadline: number | string | BigNumber;
};

export type BuyTokensObj20 = {
  recipient: string;
  maxCurrency: number | string | BigNumber;
  extraFeeRecipients: string[];
  extraFeeAmounts: number[] | string[] | BigNumber[];
  deadline: number | string | BigNumber;
};

export type SellTokensObj = {
  recipient: string;
  minBaseTokens: number | string | BigNumber;
  deadline: number | string | BigNumber;
};

export type SellTokensObj20 = {
  recipient: string;
  minCurrency: number | string | BigNumber;
  extraFeeRecipients: string[];
  extraFeeAmounts: number[] | string[] | BigNumber[];
  deadline: number | string | BigNumber;
};

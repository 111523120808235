import { createContext, useContext, useReducer } from "react";

const defaultGlobalState = {
  usdcContract: null,
  exchangeTradeContract: null,
  skyweaverQueryContract: null,
  account: null,
  balance: null,
  needApproval: true,
  cardList: null,
  provider: null,
  sequenceWallet: null,
  loadingAccount: true,
  shoppingCart: new Map(),
};
const globalStateContext = createContext(defaultGlobalState);
const dispatchStateContext = createContext(undefined);

export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, newValue) => {
    return { ...state, ...newValue };
  }, defaultGlobalState);
  return (
    <globalStateContext.Provider value={state}>
      <dispatchStateContext.Provider value={dispatch}>
        {children}
      </dispatchStateContext.Provider>
    </globalStateContext.Provider>
  );
};

export const useGlobalState = () => [
  useContext(globalStateContext),
  useContext(dispatchStateContext),
];

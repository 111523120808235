import AccountModal from "./AccountModal";
import SupportModal from "./SupportModal";

const ModalManager = ({ onClose, modal = "" }) => {
  return (
    <>
      <AccountModal onClose={onClose} isOpen={modal === "Account"} />
      <SupportModal onClose={onClose} isOpen={modal === "Support"} />
    </>
  );
};

export default ModalManager;

import { ReactNode, useState } from "react";
import {
  Box,
  chakra,
  Container,
  Flex,
  Spacer,
  useDisclosure,
  Image,
  Text,
  Link,
  Button,
  IconButton,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
} from "@chakra-ui/react";
import ConnectButton from "./ConnectButton";
import React from "react";
import { useViewportScroll } from "framer-motion";
import ModalManager from "./ModalManager";
import AccountButton from "./AccountButton";
import { useGlobalState } from "../hooks/GlobalState";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import styles from "../css/web3.module.css";
import LanguageModal from "./LanguageModal";
import Banner from "./Banner";

type Props = {
  children?: ReactNode;
};

export default function Header() {
  const {
    isOpen: isLanguageOpen,
    onOpen: onLanguageOpen,
    onClose: onLanguageClose,
  } = useDisclosure();
  const { t, i18n } = useTranslation("translation");
  const [globalState, dispatch] = useGlobalState();
  const [modalOpen, setModal] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mobileNav = useDisclosure();

  const ref = React.useRef();
  const [y, setY] = React.useState(0);

  const { scrollY } = useViewportScroll();

  React.useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()));
  }, [scrollY]);

  const openModal = (event) => {
    event.preventDefault();
    let element = event.target;
    while (element != null) {
      if (element.dataset.modal) {
        ReactGA.event({
          category: "Activity",
          action: "Open " + element.dataset.modal,
        });
        setModal(element.dataset.modal);
        return;
      }
      element = element.parentElement;
    }
  };

  const closeModal = () => {
    setModal("");
  };

  const walletButton = globalState.account ? (
    <Container maxW="310px" align="right" p="0" m="0">
      <AccountButton
        handleOpenModal={openModal}
        account={globalState.account}
      />
    </Container>
  ) : (
    <Box marginLeft={"20px"}>
      <ConnectButton showOther={false} />
    </Box>
  );

  return (
    <Box>
      <chakra.header
        display={{ base: "none", md: "none", lg: "block" }}
        zIndex={3}
        ref={ref}
        shadow={"sm"}
        transition="box-shadow 0.2s"
        bg={"gray.900"}
        w="full"
        overflowY="hidden"
        h={"100px"}
        alignContent={["center"]}
        position={"fixed"}
      >
        {/* <Text textAlign={"right"} color="red">
          The website has hits the today's API limits. We are working on a fix for it right now.
          <br />
          Connecting to your wallet is currently disabled.
        </Text> */}
        <chakra.div h="4.5rem" mx="auto" height="full">
          <Flex
            w="full"
            h="full"
            px="6"
            align="center"
            justify="space-between"
            positon={"fixed"}
          >
            <Link color="white" fontSize={"30px"} fontWeight={"bold"} href="/">
              SkyweaverSwap
            </Link>
            <Spacer />
            <Link
              variant="unstyled"
              fontSize={"20px"}
              p={"15px"}
              href="/Guide"
              color="white"
            >
              {t("common.guideLabel")}
            </Link>
            <Link
              variant="unstyled"
              fontSize={"20px"}
              p={"15px"}
              href="/Market"
              color="white"
            >
              {t("common.marketLabel")}
            </Link>
            <Link
              variant="unstyled"
              fontSize={"20px"}
              p={"15px"}
              href="/Data"
              color="white"
            >
              {t("common.dataLabel")}
            </Link>
            <Spacer />
            <Spacer />
            <Flex
              justify="flex-end"
              w="full"
              maxW="384px"
              align="right"
              color="gray.400"
            >
              <IconButton
                onClick={onLanguageOpen}
                aria-label={"translation"}
                colorScheme={"teal"}
                backgroundColor={"transparent"}
                icon={
                  <Image
                    width={"8"}
                    filter="brightness(0) invert(1);"
                    src="/icon/translation.png"
                    paddingRight="5px"
                  />
                }
              ></IconButton>
              {walletButton}
            </Flex>
          </Flex>
          <LanguageModal isOpen={isLanguageOpen} onClose={onLanguageClose} />
          <ModalManager onClose={closeModal} modal={modalOpen} />
        </chakra.div>
      </chakra.header>
    </Box>
  );
}

import {
  Box,
  Modal,
  Image,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  AspectRatio,
  HStack,
  VStack,
  Spacer,
  Input,
  Divider,
  Container,
} from "@chakra-ui/react";
import { BigNumber, ethers } from "ethers";
import { author_wallet, EXCHANGE_ADDRESS } from "../constant";
import { useEffect, useState } from "react";
import {
  getSellTokenData20,
  methodsSignature20,
  SellTokens20Type,
  SellTokensObj20,
} from "../typings";
import { useGlobalState } from "../../hooks/GlobalState";
import {
  sksBuyPriceRate,
  sksFeeRate,
  sksSellPriceRate,
} from "../../constants/exchange";
import ReactGA from "react-ga";
import styles from "../../css/web3.module.css";
import { Decimal } from "decimal.js";
import { useTranslation } from "react-i18next";
import ApproveUSDCButton from "./ApproveUSDCButton";
import uuid from "react-uuid";

type Props = {
  isOpen: any;
  onClose: any;
  card: any;
  succeedCallback: any;
};

export default function ManageAssetModal({
  isOpen,
  onClose,
  card,
  succeedCallback,
}: Props) {
  const { t, i18n } = useTranslation("translation");
  const [globalState, dispatch] = useGlobalState();
  const [amount, setAmount] = useState(new Decimal(0)); // '' is the initial state value
  const textColor = "white";
  const [cardPrice, setCardPrice] = useState({
    sell: new Decimal(card["card_sksSellPrice"]),
    buy: new Decimal(card["card_sksBuyPrice"]),
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [showApproval, setShowApproval] = useState(false);

  const closeModal = (showConfirm = false) => {
    setShowApproval(false);
    onClose(showConfirm);
  };

  useEffect(() => {
    if (amount.isNaN()) {
      setErrorMessage(t("translation:market.error.invalidAmount"));
      return;
    }
    if (!amount.equals(new Decimal(amount.toFixed(2)))) {
      setErrorMessage(t("translation:market.error.minimumAmount"));
      return;
    }
    if (amount.comparedTo(20) > 0) {
      setErrorMessage(t("translation:market.error.buyLimit"));
      return;
    }
    setErrorMessage(null);
    updatePrice();
  }, [amount]);

  const updatePrice = async () => {
    if (card["pool_cardInPool"] === 0) {
      return;
    }
    setCardPrice({ sell: new Decimal(-1), buy: new Decimal(-1) });
    console.log("assetModal");
    var cardSellPrice =
      await globalState.exchangeTradeContract.getPrice_tokenToCurrency(
        [card["ID"]],
        [ethers.BigNumber.from(amount.times(100).floor().toNumber())]
      );
    const sellPrice = new Decimal(cardSellPrice[0].toNumber())
      .times(sksSellPriceRate)
      .div(1000000);
    let buyPrice;
    try {
      var cardBuyPrice =
        await globalState.exchangeTradeContract.getPrice_currencyToToken(
          [card["ID"]],
          [ethers.BigNumber.from(amount.times(100).floor().toNumber())]
        );
      buyPrice = new Decimal(cardBuyPrice[0].toNumber())
        .times(sksBuyPriceRate)
        .div(1000000);
    } catch (e) {
      buyPrice = new Decimal(NaN);
    }

    if (
      errorMessage &&
      errorMessage.includes(t("translation:market.error.waitForPriceUpdate"))
    ) {
      setErrorMessage(null);
    }
    setCardPrice({
      sell: sellPrice,
      buy: buyPrice,
    });
  };

  const buy = async () => {
    if (globalState.needApproval) {
      setShowApproval(true);
      return;
    }
    setShowApproval(false);
    const accountBalance = globalState.balance;
    if (cardPrice["buy"].equals(-1)) {
      setErrorMessage(t("translation:market.error.waitForPriceUpdate"));
      return;
    }
    if (cardPrice["buy"].comparedTo(accountBalance) > 0) {
      setErrorMessage(t("translation:market.error.notEnoughUSDCToBuy"));
      return;
    }
    if (amount.equals(0)) {
      setErrorMessage(t("translation:market.error.pleaseEnterAmount"));
      return;
    }
    if (errorMessage != null) {
      if (
        errorMessage.includes(t("translation:market.error.waitForPriceUpdate"))
      ) {
        setErrorMessage(null);
      } else {
        return;
      }
    }
    const card_id_list = [];
    const card_count_list = [];
    const BUY_LIST = [
      {
        ID: card["ID"],
        amount: amount,
        cardDetail: card,
      },
    ];
    BUY_LIST.sort((a, b) => parseFloat(a["ID"]) - parseFloat(b["ID"]));
    BUY_LIST.forEach((liquidity_card) => {
      const card = liquidity_card["cardDetail"];
      card_id_list.push(ethers.BigNumber.from(liquidity_card["ID"]));
      card_count_list.push(
        ethers.BigNumber.from(amount.times(100).floor().toNumber())
      );
    });

    closeModal(true);
    ReactGA.event({
      category: "Asset",
      action: "Buy transfered to wallet",
    });
    const transfer = await globalState.exchangeTradeContract.buyTokens(
      card_id_list,
      card_count_list,
      ethers.BigNumber.from(cardPrice["buy"].times(1000000).floor().toNumber()),
      // 2h as deadline
      ethers.BigNumber.from(Math.floor(Date.now() / 1000) + 60 * 60 * 2),
      globalState.account,
      [author_wallet],
      [
        ethers.BigNumber.from(
          cardPrice["buy"].times(1000000).times(sksFeeRate).floor().toNumber()
        ),
      ]
    );
    try {
      await transfer.wait();
      ReactGA.event({
        category: "Asset",
        action: "Successful Buy Trade",
        label: card["rarity"],
        value: cardPrice["buy"].times(100).floor().toNumber(),
        dimension1: card["name"],
      });
      ReactGA.plugin.require("ecommerce");
      ReactGA.plugin.execute("ecommerce", "addTransaction", {
        id: uuid(), // Transaction ID. Required.
        name: card["name"], // Product name. Required.
        sku: card["name"], // SKU/code.
        category: card["rarity"], // Category or variation.
        price: cardPrice["buy"].toNumber().toFixed(2),
        quantity: amount.toNumber(),
        revenue: cardPrice["buy"].toNumber().toFixed(2), // Grand Total.
      });
      ReactGA.plugin.execute("ecommerce", "send", null);
      ReactGA.plugin.execute("ecommerce", "clear", null);

      succeedCallback();
    } catch (error) {
      ReactGA.event({
        category: "Asset",
        action: "Failed Buy after transfered to wallet",
      });
    }
  };

  const sell = async () => {
    if (cardPrice["sell"].equals(-1)) {
      setErrorMessage(t("translation:market.error.waitForPriceUpdate"));
      return;
    }
    if (amount.comparedTo(card["self_cardOwned"]) > 0) {
      setErrorMessage(t("translation:market.error.notEnoughCardToSell"));
      return;
    }
    if (errorMessage != null) {
      if (
        errorMessage.includes(t("translation:market.error.waitForPriceUpdate"))
      ) {
        setErrorMessage(null);
      } else {
        return;
      }
    }
    const card_id_list = [];
    const card_count_list = [];
    const card_min_cap_list = [];
    const card_min_token_list = [];
    const SELL_LIST = [
      {
        ID: card["ID"],
        amount: amount,
        cardDetail: card,
      },
    ];
    SELL_LIST.sort((a, b) => parseFloat(a["ID"]) - parseFloat(b["ID"]));
    // let fee = new Decimal(0);
    SELL_LIST.forEach((liquidity_card) => {
      // const card = liquidity_card['cardDetail'];
      card_id_list.push(ethers.BigNumber.from(liquidity_card["ID"]));
      card_count_list.push(
        ethers.BigNumber.from(amount.times(100).floor().toNumber())
      );
      // fee = fee.add(amount.times(card["card_sksSellFee"]));
    });

    closeModal(true);
    ReactGA.event({
      category: "Asset",
      action: "Sell transfered to wallet",
    });
    const transfer =
      await globalState.skyweaverQueryContract.safeBatchTransferFrom(
        globalState.account,
        EXCHANGE_ADDRESS,
        card_id_list,
        card_count_list,
        getSellTokenData20(
          globalState.account,
          ethers.BigNumber.from(
            cardPrice["sell"].times(1000000).floor().toNumber()
          ),
          // 2h as deadline
          Math.floor(Date.now() / 1000) + 60 * 60 * 2,
          [author_wallet],
          [
            ethers.BigNumber.from(
              cardPrice["sell"]
                .times(1000000)
                .times(sksFeeRate)
                .floor()
                .toNumber()
            ),
          ]
        )
      );
    try {
      await transfer.wait();
      ReactGA.event({
        category: "Asset",
        action: "Successful Sell Trade",
        label: card["rarity"],
        value: cardPrice["sell"].times(100).floor().toNumber(),
        dimension1: card["name"],
      });
      ReactGA.plugin.require("ecommerce");
      ReactGA.plugin.execute("ecommerce", "addTransaction", {
        id: uuid(), // Transaction ID. Required.
        name: card["name"], // Product name. Required.
        sku: card["name"], // SKU/code.
        category: card["rarity"], // Category or variation.
        price: cardPrice["sell"].toNumber().toFixed(2),
        quantity: amount.toNumber(),
        revenue: cardPrice["sell"].toNumber().toFixed(2), // Grand Total.
      });
      ReactGA.plugin.execute("ecommerce", "send", null);
      ReactGA.plugin.execute("ecommerce", "clear", null);
      succeedCallback();
    } catch (error) {
      ReactGA.event({
        category: "Asset",
        action: "Failed Sell after transfered to wallet",
      });
    }
  };

  const inputSection = (
    <HStack p={"20px"}>
      <Input
        minW="60px"
        minH="45px"
        color={textColor}
        defaultValue={"0"}
        onChange={(e) => {
          try {
            const value = parseFloat(e.target.value);
            setAmount(new Decimal(value));
          } catch (e) {
            // Won't update the price.
          }
        }}
      />
      <button
        className={[
          styles.button,
          styles.buttonGradientBorder,
          styles.buttonGlow,
          styles.xsmallButton,
        ].join(" ")}
        onClick={buy}
      >
        {t("translation:market.action.buy")}
      </button>
      <Spacer />
      <button
        className={[
          styles.button,
          styles.buttonGradientBorder,
          styles.buttonGlow,
          styles.xsmallButton,
        ].join(" ")}
        onClick={sell}
      >
        {t("translation:market.action.sell")}
      </button>
    </HStack>
  );

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered size="xl">
      <ModalOverlay />
      <ModalContent
        background="gray.900"
        border="1px"
        borderStyle="solid"
        borderColor="gray.700"
        borderRadius="3xl"
      >
        <ModalHeader color="white" px={4} fontSize="lg" fontWeight="medium">
          {card["name"]}
        </ModalHeader>
        <ModalCloseButton
          color="white"
          fontSize="sm"
          _hover={{
            color: "whiteAlpha.700",
          }}
        />
        <ModalBody pt={0} px={4}>
          <Box
            borderRadius="3xl"
            border="1px"
            borderStyle="solid"
            borderColor="gray.600"
            px={5}
            pt={4}
            pb={2}
            mb={3}
          >
            <VStack>
              <HStack
                alignItems="flex-start"
                spacing={3}
                marginBottom={"20px"}
                w={300}
              >
                <AspectRatio ratio={0.65} w={24}>
                  <Image src={card["Image_URL"]} alt="Card" />
                </AspectRatio>
                <VStack spacing={3} align={"left"}>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {card["rarity"] === "crystal"
                      ? t("translation:market.card.crystalOwned")
                      : t("translation:market.card.cardOwned")}
                    : {new Decimal(card["self_cardOwned"]).toFixed(2)}
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.supply")}:{" "}
                    {card["supply"].toLocaleString("en-US")}
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.stock")}:{" "}
                    {card["pool_cardInPool"]}
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.sellPriceV2")}:{" "}
                    {new Decimal(card["card_sksSellPrice"]).toFixed(
                      2,
                      Decimal.ROUND_DOWN
                    )}
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.buyPriceV2")}:{" "}
                    {new Decimal(card["card_sksBuyPrice"]).toFixed(
                      2,
                      Decimal.ROUND_DOWN
                    )}
                  </Text>
                  <Divider />
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.totalSellPrice")}:{" "}
                    {cardPrice["sell"].equals(-1)
                      ? "Loading"
                      : cardPrice["sell"].toFixed(2, Decimal.ROUND_DOWN)}
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.totalBuyPrice")}:{" "}
                    {cardPrice["buy"].equals(-1)
                      ? "Loading"
                      : cardPrice["buy"].isNaN()
                      ? "No stock"
                      : cardPrice["buy"].toFixed(2, Decimal.ROUND_DOWN)}
                  </Text>
                </VStack>
              </HStack>
              <Divider />
              {inputSection}
              <Container
                textAlign={"center"}
                display={errorMessage ? "block" : "none"}
              >
                <Text color="red" padding-bottom="10px">
                  {errorMessage}
                </Text>
                <Spacer />
              </Container>
              <Container display={showApproval ? "block" : "none"}>
                <ApproveUSDCButton />
              </Container>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

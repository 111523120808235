import {
  Box,
  Modal,
  Image,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  AspectRatio,
  HStack,
  VStack,
  Spacer,
  Input,
  Divider,
  Container,
} from "@chakra-ui/react";
import { BigNumber, ethers } from "ethers";
import { EXCHANGE_ADDRESS } from "../constant";
import { useEffect, useState } from "react";
import {
  AddLiquidityObj,
  AddLiquidityType,
  methodsSignature20,
  RemoveLiquidityObj,
  RemoveLiquidityType,
} from "../typings";
import { useGlobalState } from "../../hooks/GlobalState";
import ReactGA from "react-ga";
import { enableCustomLiquidityPrice } from "../../constants/flags";
import styles from "../../css/web3.module.css";
import { Decimal } from "decimal.js";
import ApproveUSDCButton from "./ApproveUSDCButton";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

type Props = {
  isOpen: any;
  onClose: any;
  card: any;
  successCallback: any;
};

export default function ManageLiquidityModal({
  isOpen,
  onClose,
  card,
  successCallback,
}: Props) {
  const { t, i18n } = useTranslation("translation");
  const [globalState, dispatch] = useGlobalState();
  const [amount, setAmount] = useState(new Decimal(0)); // '' is the initial state value
  const [liquidityUSDC, setLiquidityUSDC] = useState(new Decimal(0));
  const textColor = "white";
  const [errorMessage, setErrorMessage] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const liquidityPriceEnabled =
    enableCustomLiquidityPrice || searchParams.get("liquidityprice") === "1";

  useEffect(() => {
    if (amount.isNaN()) {
      setErrorMessage("Invalid amount");
      return;
    }
    if (!amount.equals(new Decimal(amount.toFixed(2)))) {
      setErrorMessage("Minimum amount is 0.01");
      return;
    }
    if (amount.comparedTo(50) > 0) {
      setErrorMessage("You can modify up to 50 cards at a time");
      return;
    }
    setErrorMessage(null);
  }, [amount]);

  const addLiquidity = async () => {
    if (errorMessage != null) {
      return;
    }
    const accountBalance = globalState.balance;
    const usdcLiquidity = amount
      .times(card["pool_liquidityValue"])
      .toDP(2, Decimal.ROUND_DOWN);
    if (!liquidityPriceEnabled) {
      if (usdcLiquidity.equals(0)) {
        setErrorMessage("Please wait for the usdc liquidity to be updated");
        return;
      }
      if (usdcLiquidity.comparedTo(accountBalance) > 0) {
        setErrorMessage("Not enough USDC to add liquidity");
        return;
      }
    }
    if (
      amount.comparedTo(
        new Decimal(card["self_cardOwned"]).toDP(2, Decimal.ROUND_DOWN)
      ) > 0
    ) {
      setErrorMessage("Not enough cards to add liquidity");
      return;
    }
    // baseAmountsToAdd: BigNumber[], deadline: number
    const getAddLiquidityData = (
      maxCurrency: BigNumber[],
      deadline: number
    ) => {
      const addLiquidityObj = { maxCurrency, deadline } as AddLiquidityObj;

      return ethers.utils.defaultAbiCoder.encode(
        ["bytes4", AddLiquidityType],
        [methodsSignature20.ADDLIQUIDITY, addLiquidityObj]
      );
    };
    const card_id_list = [];
    const card_count_list = [];
    const card_cap_list = [];
    const ADD_CARD_LIST = [
      {
        ID: card["ID"],
        Liquidity_Amount: amount,
      },
    ];
    ADD_CARD_LIST.sort((a, b) => parseFloat(a["ID"]) - parseFloat(b["ID"]));
    ADD_CARD_LIST.forEach((card) => {
      card_id_list.push(ethers.BigNumber.from(card["ID"]));
      card_count_list.push(
        ethers.BigNumber.from(card["Liquidity_Amount"].times(100).toNumber())
      );
      if (liquidityPriceEnabled) {
        card_cap_list.push(
          ethers.BigNumber.from(
            ethers.BigNumber.from(liquidityUSDC.times(1000000).toNumber())
          )
        );
      } else {
        card_cap_list.push(
          ethers.BigNumber.from(ethers.BigNumber.from("1000000000"))
        );
      }
    });
    onClose(true);
    ReactGA.event({
      category: "Asset",
      action: "Add Liquidity transfered to wallet",
    });
    const transfer =
      await globalState.skyweaverQueryContract.safeBatchTransferFrom(
        globalState.account,
        EXCHANGE_ADDRESS,
        card_id_list,
        card_count_list,
        getAddLiquidityData(
          card_cap_list,
          // 2h as deadline
          Math.floor(Date.now() / 1000) + 60 * 60 * 2
        )
      );
    try {
      await transfer.wait();
      ReactGA.event({
        category: "Asset",
        action: "Successful Add Liquidity",
        label: card["rarity"],
        value: usdcLiquidity.times(100).floor().toNumber(),
        dimension1: card["name"],
      });
      successCallback();
    } catch (error) {
      ReactGA.event({
        category: "Asset",
        action: "Failed Add Liquidity after transfered to wallet",
      });
    }
  };

  const removeLiquidity = async () => {
    if (errorMessage != null) {
      return;
    }
    if (
      amount.comparedTo(
        new Decimal(card["pool_ownedCardInPool"]).toDP(2, Decimal.ROUND_DOWN)
      ) > 0
    ) {
      setErrorMessage("Not enough cards to remove from the pool");
      return;
    }
    // baseAmountsToAdd: BigNumber[], deadline: number as epoch time in seconds
    const getRemoveLiquidityData = (
      minCurrency: BigNumber[],
      minTokens: BigNumber[],
      deadline: number
    ) => {
      const removeLiquidityObj = {
        minCurrency,
        minTokens,
        deadline,
      } as RemoveLiquidityObj;

      return ethers.utils.defaultAbiCoder.encode(
        ["bytes4", RemoveLiquidityType],
        [methodsSignature20.REMOVELIQUIDITY, removeLiquidityObj]
      );
    };
    const card_id_list = [];
    const card_count_list = [];
    const card_min_cap_list = [];
    const card_min_token_list = [];
    const REMOVE_CARD_LIST = [
      {
        ID: card["ID"],
        Liquidity_Amount: amount,
        cardDetail: card,
      },
    ];
    REMOVE_CARD_LIST.sort((a, b) => parseFloat(a["ID"]) - parseFloat(b["ID"]));
    REMOVE_CARD_LIST.forEach((liquidity_card) => {
      const card = liquidity_card["cardDetail"];
      const contract_amount = ethers.BigNumber.from(
        amount
          .div(
            new Decimal(card["pool_ownedCardInPool"]).toDP(
              2,
              Decimal.ROUND_DOWN
            )
          )
          .times(card["pool_ownedShare"].toNumber())
          .ceil()
          .toNumber()
      );
      card_id_list.push(ethers.BigNumber.from(liquidity_card["ID"]));
      card_count_list.push(ethers.BigNumber.from(contract_amount));
      card_min_cap_list.push(ethers.BigNumber.from(ethers.BigNumber.from("0")));
      card_min_token_list.push(
        ethers.BigNumber.from(amount.times(100).floor().toNumber())
      );
    });
    onClose(true);
    ReactGA.event({
      category: "Asset",
      action: "Remove Liquidity transfered to wallet",
    });
    const transfer =
      await globalState.exchangeTradeContract.safeBatchTransferFrom(
        globalState.account,
        EXCHANGE_ADDRESS,
        card_id_list,
        card_count_list,
        getRemoveLiquidityData(
          card_min_cap_list,
          card_min_token_list,
          // 30min as deadline
          Math.floor(Date.now() / 1000) + 60 * 30
        )
      );
    try {
      await transfer.wait();
      const usdcLiquidity = amount
        .times(card["pool_liquidityValue"])
        .toDP(2, Decimal.ROUND_DOWN);
      ReactGA.event({
        category: "Asset",
        action: "Successful Remove Liquidity",
        label: card["rarity"],
        value: usdcLiquidity.times(100).floor().toNumber(),
        dimension1: card["name"],
      });
      successCallback();
    } catch (error) {
      ReactGA.event({
        category: "Asset",
        action: "Failed Remove Liquidity after transfered to wallet",
      });
    }
  };

  const inputSection = (
    <HStack p={"20px"}>
      <Input
        minW="60px"
        minH="45px"
        placeholder="Number of cards"
        defaultValue={"0"}
        color={textColor}
        onChange={(e) => setAmount(new Decimal(parseFloat(e.target.value)))}
      />
      {liquidityPriceEnabled ? (
        <Input
          minW="60px"
          minH="45px"
          placeholder="Total USDC"
          color={textColor}
          onChange={(e) =>
            setLiquidityUSDC(new Decimal(parseFloat(e.target.value)))
          }
        />
      ) : null}
      <button
        className={[
          styles.button,
          styles.buttonGradientBorder,
          styles.buttonGlow,
          styles.smallButton,
        ].join(" ")}
        onClick={addLiquidity}
      >
        {t("translation:market.action.addLiquidity")}
      </button>
      <Spacer />
      <button
        className={[
          styles.button,
          styles.buttonGradientBorder,
          styles.buttonGlow,
          styles.smallButton,
        ].join(" ")}
        onClick={removeLiquidity}
      >
        {t("translation:market.action.withdrawLiquidity")}
      </button>
    </HStack>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent
        background="gray.900"
        border="1px"
        borderStyle="solid"
        borderColor="gray.700"
        borderRadius="3xl"
      >
        <ModalHeader color="white" px={4} fontSize="lg" fontWeight="medium">
          {card["name"]}
        </ModalHeader>
        <ModalCloseButton
          color="white"
          fontSize="sm"
          _hover={{
            color: "whiteAlpha.700",
          }}
        />
        <ModalBody pt={0} px={4}>
          <Box
            borderRadius="3xl"
            border="1px"
            borderStyle="solid"
            borderColor="gray.600"
            px={5}
            pt={4}
            pb={2}
            mb={3}
          >
            <VStack>
              <HStack alignItems="flex-start" spacing={3} marginBottom={"20px"}>
                <AspectRatio ratio={0.65} w={24}>
                  <Image src={card["Image_URL"]} alt="Card" />
                </AspectRatio>
                <VStack spacing={3} align={"left"}>
                  <Text
                    justifyContent="flex-start"
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.poolOwnership")}:{" "}
                    {Math.round(card["pool_ownedPercentage"] * 10000) / 100}%
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.usdcInPool")}:{" "}
                    {card["pool_cashReserves"]}
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.cardInPool")}:{" "}
                    {card["pool_cardInPool"]}
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.cardLiquidityValue")}:{" "}
                    {card["pool_liquidityValue"]}
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.cardOwned")}:{" "}
                    {card["self_cardOwned"]}
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.sellPrice")}:{" "}
                    {card["card_sksSellPrice"]}
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.buyPrice")}:{" "}
                    {card["card_sksBuyPrice"]}
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.ownedCardInPool")}:{" "}
                    {new Decimal(card["pool_ownedCardInPool"]).toFixed(
                      2,
                      Decimal.ROUND_DOWN
                    )}
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.ownedCardInPoolValue")}:{" "}
                    {card["pool_ownedCardValue"]}
                  </Text>
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.ownedCashValue")}:{" "}
                    {card["pool_ownedCashValue"]}
                  </Text>
                  <Divider />
                  <Text
                    color={textColor}
                    textAlign="left"
                    fontWeight="medium"
                    fontSize="md"
                  >
                    {t("translation:market.card.usdcForLiquidity")}:{" "}
                    {isNaN(card["pool_liquidityValue"])
                      ? 0
                      : amount
                          .times(card["pool_liquidityValue"])
                          .toFixed(2, Decimal.ROUND_DOWN)}
                  </Text>
                </VStack>
              </HStack>
              <Divider />
              {globalState.needApproval ? <ApproveUSDCButton /> : inputSection}
              <Container
                textAlign={"center"}
                display={errorMessage ? "block" : "none"}
              >
                <Text color="red" padding-bottom="10px">
                  {errorMessage}
                </Text>
                <Spacer />
              </Container>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
